<template>
  <b-card
    v-if="courseData"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        General View
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col  xl="3"
          sm="6"  v-if="courseData"
          :class="courseData.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="courseData.color"
              >
                <feather-icon
                  size="24"
                  :icon="courseData.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ courseData.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ courseData.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
          <b-col  xl="3"
          sm="6"  v-if="participiantData"
          :class="participiantData.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="participiantData.color"
              >
                <feather-icon
                  size="24"
                  :icon="participiantData.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ participiantData.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ participiantData.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
          <b-col  xl="3"
          sm="6"  v-if="examData"
          :class="examData.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="examData.color"
              >
                <feather-icon
                  size="24"
                  :icon="examData.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ examData.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ examData.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
          <b-col  xl="3"
          sm="6"  v-if="certificateData"
          :class="certificateData.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="certificateData.color"
              >
                <feather-icon
                  size="24"
                  :icon="certificateData.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ certificateData.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ certificateData.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

import userStoreModule from './analitiycStoreModule'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-analytics'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
   
  
    
   const courseData = ref(null);
     
   const participiantData = ref(null);
     
   const examData = ref(null);
     
   const certificateData = ref(null);

    var statisticsItems=ref(null);
    statisticsItems.value=[
     courseData,
participiantData,
examData,
certificateData
  ];


  store.dispatch('app-analytics/fetchCourseStats', { id: router.currentRoute.params.id })
      .then(response => {
        courseData.value = {
      icon: 'TrendingUpIcon',
      color: 'light-primary',
      title: response.data.data,
      subtitle: 'Courses',
      customClass: 'mb-2 mb-xl-0',
    } ;
        console.log( courseData.value);

        })
      .catch(error => {
        if (error.response.status === 404) {
          courseData.value = undefined
        }
      });
       store.dispatch('app-analytics/fetchSubscribersCount', { id: router.currentRoute.params.id })
      .then(response => {
   
        participiantData.value ={
      icon: 'UserIcon',
      color: 'light-info',
      title: response.data.data,
      subtitle: 'Participants',
      customClass: 'mb-2 mb-xl-0',
    }
        })
      .catch(error => {
        if (error.response.status === 404) {
          participiantData.value = undefined
        }
      });
       store.dispatch('app-analytics/fetchExamStats', { id: router.currentRoute.params.id })
      .then(response => {
   
        examData.value =  {
      icon: 'BoxIcon',
      color: 'light-danger',
      title: response.data.data,
      subtitle: 'Exams',
      customClass: 'mb-2 mb-sm-0',
    }, response.data.data;
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          examData.value = undefined
        }
      });
       store.dispatch('app-analytics/fetchCertStats', { id: router.currentRoute.params.id })
      .then(response => {
  
        certificateData.value =  {
      icon: 'DollarSignIcon',
      color: 'light-success',
      title: response.data.data,
      subtitle: 'Certificates',
      customClass: '',
    } ;
    
        
        })
      .catch(error => {
        if (error.response.status === 404) {
          certificateData.value = undefined
        }
      });

    

    return {

      courseData,
      participiantData,
      examData,
      certificateData,
      statisticsItems

    }
  },

}
</script>
